<template>
    <div>
        <div class="alert_popup" v-if="_modal.alertState">
            <div class="popup_con">
                <p v-html="_modal.msg"></p>
                <button type="button" class="btn_fill" @click="closeAlert">
                    확인
                </button>
            </div>
        </div> 
        <div class="alert_popup" v-if="_modal.alertTitleState">
            <div class="popup_con_title">
                <p class='title' v-html="_modal.title"></p>
                <p class="txt" v-html="_modal.msg"></p>
                <button type="button" class="btn_fill" @click="closeAlert">
                    확인
                </button>
            </div>
        </div> 
        <div class="popWrap creatCheck02" v-if="_modal.confirmState">
            <div class="popCon">
                <div class="contents">
                    <p class="alertBox" style="white-space: pre-line" v-html="_modal.msg"></p>
                    <div class="btnWrap02">
                        <button type="button" class="btnCnl" @click="closeAlert">{{ _modal.btn_cl }}</button>
                        <button type="button" class="btnChk" @click="confirmAlert">{{ _modal.btn_co }}</button>
                    </div>
                </div>
            </div>
        </div> 
        <div class="popWrap creatCheck02" v-if="_modal.confirmCancelState">
            <div class="popCon">
                <div class="contents">
                    <p class="alertBox" style="white-space: pre-line" v-html="_modal.msg"></p>
                    <div class="btnWrap02">
                        <button type="button" class="btnCnl" @click="cancelAlert">{{ _modal.btn_cl }}</button>
                        <button type="button" class="btnChk" @click="confirmAlert">{{ _modal.btn_co }}</button>
                    </div>
                </div>
            </div>
        </div>         
        <div class="popWrap creatCheck02" v-if="_modal.loginState">
            <div class="popCon">
                <div class="contents">
                    <p class="alertBox" style="white-space: pre-line;text-align: center;">{{ _modal.msg }}</p>
                    <div class="btnWrap02">
                        <button type="button" class="btnCnl" @click="closeSubscribeAlert" style="width:30%;">취소</button>
                        <button type="button" class="btnChk" @click="confirmAlert" style="width:60%;">3초만에 로그인</button>
                    </div>
                </div>
            </div>
        </div> 
    </div>
    </template>
<script>

import { mapState } from 'vuex';

require('@/assets/css/popup.css')

export default {
name: 'AlertPopup',
    props: ['context', 'test'],
    data() {
        return {
            idx: 0
        }
    },
    computed: {
        ...mapState(['_state','_modal'])
    },
    methods: {
        closeAlert() {
            this._modal.msg = '';
            this._modal.alertBool = false;
            this._modal.alertState = false;
            this._modal.alertTitleState = false;
            this._modal.confirmState = false;
            this._modal.loginState = false;
            this._modal.confirmCancelState = false;
            this._modal.event = '';
            this._modal.event02 = '';
        },
        closeSubscribeAlert() {
            this._modal.msg = '';
            this._modal.alertBool = false;
            this._modal.alertState = false;
            this._modal.alertTitleState = false;
            this._modal.confirmState = false;
            this._modal.loginState = false;
            this._modal.withdrawState = false;
            this._modal.confirmCancelState = false;
            this._modal.event = '';
            this._modal.event02 = '';
        },
        confirmAlert(){
            this._modal.event();
            this._modal.msg = '';
            this._modal.alertBool = false;
            this._modal.alertState = false;
            this._modal.alertTitleState = false;
            this._modal.confirmState = false;
            this._modal.loginState = false;
            this._modal.confirmCancelState = false;
            this._modal.event = '';
            this._modal.event02 = '';
        },
        cancelAlert(){
            this._modal.event02();
            this._modal.msg = '';
            this._modal.alertBool = false;
            this._modal.alertState = false;
            this._modal.alertTitleState = false;
            this._modal.confirmState = false;
            this._modal.loginState = false;
            this._modal.confirmCancelState = false;
            this._modal.event = '';
            this._modal.event02 = '';
        },
        withDrawAlert() {
            this._modal.msg = '';
            this._modal.alertBool = false;
            this._modal.alertState = false;
            this._modal.alertTitleState = false;
            this._modal.confirmState = false;
            this._modal.loginState = false;
            this._modal.withdrawState = false;
            this._modal.confirmCancelState = false;
            this._modal.event = '';
            this._modal.event02 = '';
            this.$router.push('/mypage')
        }
    }
}
</script>
<style scoped>
.alert_popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 100;
}
.alert_popup .popup_con {
    padding-top: 75px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 462px;
    min-height: 224px;
    border-radius: 20px;
    background-color: #fff;
    padding-bottom: 30px;
}
.alert_popup .popup_con p {
    text-align: center;
    font-size: 17px;
    padding-left: 30px;
    padding-right: 30px;
}
.alert_popup .popup_con .close {
    position: absolute;
    right: 30px;
    top: 30px;
}
.alert_popup .popup_con .btn_fill {
    display: block;
    margin: 45px auto 0;
    width: 188px;
    height: 45px;
    border-radius: 5px;
    background-color: #5087FF;
    color: #fff;
}


.alert_popup .popup_con_title {
    padding-top: 27px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 343px;
    min-height: 224px;
    border-radius: 20px;
    background-color: #fff;
    padding-bottom: 30px;
}
.alert_popup .popup_con_title .title {
    text-align: center;
    font-size: 17px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 700;
    color:#2B2B2B;    
}
.alert_popup .popup_con_title .txt {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    padding-left: 30px;
    padding-right: 30px;
    color: #2B2B2B;
    margin-top:17px;
}
.alert_popup .popup_con_title .close {
    position: absolute;
    right: 30px;
    top: 30px;
}
.alert_popup .popup_con_title .btn_fill {
    display: block;
    margin: 45px auto 0;
    width: 188px;
    height: 45px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 20px;
    background-color: #5087FF;
    color: #fff;    
}

@media screen and (max-width: 1080px){
    .alert_popup .popup_con {
        padding-top: 75px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 322px;
        min-height: 224px;
        border-radius: 20px;
        background-color: #fff;
    }
    .alert_popup .popup_con_title {
        padding-top: 27px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 322px;
        min-height: 224px;
        border-radius: 20px;
        background-color: #fff;
    }
}
</style>