import Vue from "vue";
import VueRouter from "vue-router";
import previewRouter from './route-preview';
import analyzeRouter from './route-analyze';
import contractRouter from './route-contract';
import loginRouter from './route-login';
import realtorRouter from './route-realtor';
import reportRouter from './route-report';
import sciRouter from './route-sci';
import subscribeRouter from './route-subscribe';
import withdrawRouter from './route-withdraw';
import seoFeedRouter from './route-seo';
import guaranteeRouter from './route-guarantee';
import etcRouter from './route-etc';
import HomeView from "../views/analyze/AnalyzeHome.vue";
import abRouter from './route-a-b';
import ticketRouter from './route-ticket';
import orderRouter from './route-order';
import eventRouter from './route-event';

Vue.use(VueRouter);


const routes = [
    {
        path: "/ich",
        name: "home_ich",
        component: HomeView,
    },
    
    //---------------------
    //메뉴
    //---------------------
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/mypage",
        name: "mypage",
        component: () => import("../views/MyPage.vue"),        
    },
    {
        path: "/main",
        name: "main",
        component: () => import("../views/a-b/Main-AView.vue"),
    },

    //---------------------
    //공통
    //---------------------
    {
        path: "/login/error",
        name: "login-error",
        component: () => import("../views/LoginError.vue"),
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("../views/LogoutView.vue"),
    },

    //---------------------
    //기타
    //---------------------
    ...etcRouter,

    //---------------------
    //seo 피드
    //---------------------
    ...seoFeedRouter,

    //---------------------
    // 내집스캔 보증
    //---------------------
    ...guaranteeRouter,

    //---------------------
    // 분석퍼널
    //---------------------
    ...analyzeRouter,

    //---------------------
    // 미리보기
    //---------------------
    ...previewRouter,

    //---------------------
    // 계약데이터
    //---------------------
    ...contractRouter,

    //---------------------
    // 로그인
    //---------------------
    ...loginRouter,

    //---------------------
    // 공인중개사
    //---------------------
    ...realtorRouter,

    //---------------------
    // 리포트
    //---------------------
    ...reportRouter,

    //---------------------
    // sci
    //---------------------
    ...sciRouter,

    //---------------------
    // 구독
    //---------------------
    ...subscribeRouter,

    //---------------------
    // 회원탈퇴
    //---------------------
    ...withdrawRouter,

    //---------------------
    // a-b테스트
    //---------------------
    ...abRouter,

    //---------------------
    // 이용권,쿠폰
    //---------------------    
    ...ticketRouter,

    //---------------------
    // 결제
    //---------------------   
    ...orderRouter,

    //---------------------
    // 이벤트
    //---------------------       
    ...eventRouter
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
export default router;
