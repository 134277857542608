<template>
    <div>
        <article class="main home">
            <div class="content content_main">
              <img src="@/assets/images/logoNew/logo_landing_naezipscan.svg" alt="내집스캔">
                <section class="analyze_content01 main_home" >
                    <div class="main_landing_title02">
                        <img src="https://image.kredatalab.com/landing/home_landing_title_02.svg" loading="lazy">
                    </div>
                    <div class="main_landing_kimscan">
                        <img src="https://image.kredatalab.com/preview/preview_landing_kimScanReport.svg" loading="lazy">  
                    </div>                
                    <p class="main_realtor">
                        <span style="color:#3272FF;">공인중개사도 활용</span>하는 등기부등본 정밀 스캔 서비스
                    </p>     
                    <div class="main_landing_complete_flex">
                        <img src="@/assets/images/home/logo_01.svg" loading="lazy">
                        <img src="@/assets/images/home/logo_02.svg" loading="lazy">
                        <img src="@/assets/images/home/logo_03.svg" loading="lazy">
                        <img src="@/assets/images/home/logo_04.svg" loading="lazy">
                    </div> 
                    
                    <div class="pricing_slide_wrapper">
                        <div class="pricing_slide_title">
                            <p>
                            <span>누적 10만명</span>이 이용한 내집스캔<br />
                            전/월세사기 예방 리포트
                            </p>
                        </div>
                        <div class="pricing_slide">
                        <swiper :options="swiperOption">
                            <swiper-slide class="landing_slide">
                                <img class="double_quotation" src="https://image.kredatalab.com/landing/double_quotation.svg"> 
                                <div class="landing_slide_wrapper">
                                    <div class="slide_title">
                                        <img src="https://image.kredatalab.com/landing/landing_slide_house3.png"> 
                                        <p>인스타 보고 결제 했는데<br> 너무 좋아요!</p>
                                    </div>
                                    <div class="slide_owner_info">
                                        <span>최**님 (20대)</span>
                                        <span>23/08/11</span>
                                        <span>다세대주택</span>
                                    </div>
                                    <hr class="slide_hr">
                                    <p>
                                        인스타그램, 유튜브 등등 내집스캔 광고가 계속 뜨길래 관심있게 보다가 계약 앞두고 한 번 써봤어요! <br>
                                        처음 신청할 때는 반신반의해서 고민 많이 했는데 완전 만족합니다..<br><br>
                                        우선 이 집의 종합적인 위험도랑 종합의견을 제공해 주는데, 이게 직*,네이버 부**에서 보는거랑 다르게 생각보다 위험한 집들이 많아서 거를 수 있었어요. 그리고 특약사항 추천이나 집주인 세금 체납 이런 거까지 한 번에 다 확인할 수 있어서 큰 도움 되었습니다^^
                                    </p>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <img class="double_quotation" src="https://image.kredatalab.com/landing/double_quotation.svg"> 
                                <div class="landing_slide_wrapper">
                                    <div class="slide_title">
                                        <img src="https://image.kredatalab.com/landing/landing_slide_house1.png"> 
                                        <p>저처럼 후회하지 말고<br>제발 써보세요..</p>
                                    </div>
                                    <div class="slide_owner_info">
                                        <span>김**님 (30대)</span>
                                        <span>23/08/21</span>
                                        <span>오피스텔</span>
                                    </div>
                                    <hr class="slide_hr">
                                    <p>
                                        직*앱에 전세대출, 보험 가능 써있길래 그대로믿고 가계약까지 한 후에야 내집스캔을 알게 되었어요. 리포트 받아보니 대출이 거절날것 같다고 해서 급하게 은행가보니 대출 안되는게 맞다고 하더라고요.. <br><br>
                                        부동산이랑 싸우다가  한달 후에나 가계약금을 돌려 받았어요.. 지금은 다른 집 잘 구해서 대출까지 받았는데, 정말 고생했습니다 ㅜㅜ 비대면 가심사 9,900원 밖에 안하니까 전세대출 받는 분들은 꼭 써보는거 추천합니다..<br><br>
                                    </p>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <img class="double_quotation" src="https://image.kredatalab.com/landing/double_quotation.svg"> 
                                <div class="landing_slide_wrapper">
                                    <div class="slide_title">
                                        <img src="https://image.kredatalab.com/landing/landing_slide_house2.png"> 
                                        <p>결과보고 불안한 마음이<br>사라졌어요ㅜㅜ</p>
                                    </div>
                                    <div class="slide_owner_info">
                                        <span>박**님 (30대)</span>
                                        <span>23/07/21</span>
                                        <span>아파트</span>
                                    </div>
                                    <hr class="slide_hr">
                                    <p>
                                        확실히 문제 없는 집인걸 확인하고 계약했는데, 전세대출이 안되면 어떡하지,보증보험 가입이 거절나면 어떡하지 하고 잠이 안오더라고요..
                                        실제로 전세사기 당한 경험이 있다 보니 이제는 아무리 알아봐도 불안한 마음이 컸었어요 ㅜㅜㅜ<br>
                                        특히 저는 계약 다음날 은행에 갔어야 해서 너무 계약금 넣고 너무 불안한 마음이였는데, 리포트에서 완전 자세하게 평가한 결과가 문제 없다고 하니 마음이 편해졌습니다. 덕분에 은행에서도 문제 없이 대출이 나왔구요.. 좋은 서비스 만들어 주셔서 감사합니다.. <br>
                                    </p>
                                </div>
                            </swiper-slide>
                        </swiper>  
                        </div>
                    </div>
                </section>      
            </div>   
            <article class="fixed_content">
                <div class="main_landing_btn" :style="customHeight">
                    <img class="main_landing_btn_speechbubble" @click="fnAnalyze" src="https://image.kredatalab.com/SCI/icon_speechbubbleGoSCI_SCICredit.svg" style="cursor: pointer; width: auto; height: auto;" loading="lazy">
                    <div class="btn_blue_scan" @click="fnAnalyze" ><span>전세 사기 위험도 스캔하기</span></div>
                </div>   
            </article>                   
        </article>
    </div>
</template>

<script>
import '@/assets/css/common.css'
import '@/assets/css/homeview.css'
import { alertBase } from '@/mixins/alert.js'
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import mixpanel from 'mixpanel-browser';
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

const next = window.requestAnimationFrame ||
   window.webkitRequestAnimationFrame ||
   window.mozRequestAnimationFrame ||
   window.msRequestAnimationFrame ||
   window.oRequestAnimationFrame ||
   function(cb) { window.setTimeout(cb, 1000/60) }

export default {
    name: 'AnalyzeHomeView',
    components: {
        Swiper,
        SwiperSlide,
    },
    created(){
        mixpanel.track("View Analyze", {
            "platform": navigator.userAgent.toLowerCase()
        });
        this.fnCampaign();
    },
    computed: {
        ...mapState(['_state']),
        ...mapState('reportStore', ['_report', '_items']),
        customHeight () {
            if(this._state.ctaBool){
                return {'--ctaHeight': '165px'}
            }            
            else{
                return {'--ctaHeight': '90px'}
            }
        }        
    },
    mounted () {
    },
    data () {
        return {                   
            swiperOption: { 
                spaceBetween: 10, 
                loop: true, 
                pagination: { 
                    el: '.swiper-pagination', 
                    clickable: true,
                }, 
                navigation: { 
                    nextEl: '.swiper-button-next', 
                    prevEl: '.swiper-button-prev' 
                },
                autoplay: {
                    delay:3500,
                },
                slidesPerView: 1.2, 
                spaceBetween: 31, 

                
            },
        }
    },
    methods: {
        ...mapActions([
            'acIsLogin', 'acFooterState', 'acloadingState', 'acPayStatus'
        ]),     
        ...mapActions('reportStore',[
           'acOrderUserList', 'acReportPre'
        ]),    
        
        fnStopTimer(){
            clearInterval(this.handle);
            this.handle = null;
        },
        fnCampaignParams(){
            var campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ')
            , kw = ''
            , params = {}
            , first_params = {};
            var index;
            for (index = 0; index < campaign_keywords.length; ++index) {
                kw = this.fnGetQueryParam(document.URL, campaign_keywords[index]);
                if (kw.length) {
                    params[campaign_keywords[index] + ' [last touch]'] = kw;
                }
            }

            for (index = 0; index < campaign_keywords.length; ++index) {
                kw = this.fnGetQueryParam(document.URL, campaign_keywords[index]);
                if (kw.length) {
                    first_params[campaign_keywords[index] + ' [first touch]'] = kw;
                }
            }
            mixpanel.people.set(params);
            mixpanel.people.set_once(first_params);
            mixpanel.register_once(first_params);
        },
        fnGetQueryParam(url, param){
            param = param.replace(/[[]/, "\[").replace(/[]]/, "\]");
            var regexS = "[\?&]" + param + "=([^&#]*)",
                regex = new RegExp( regexS ),
                results = regex.exec(url);
            if (results === null || (results && typeof(results[1]) !== 'string' && results[1].length)) {
                return '';
            } 
            else {
                return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
            }
        },
        async fnLoginInit(){   
            this.isLogin = await this.acIsLogin();
            if(this.isLogin){
                this.acOrderUserList();
            }
        },
        async fnInfo(){
            const now = new Date();

            let day = now.getDate();
            let month = now.getMonth() + 1;
            let hour = now.getHours();
            let min = now.getMinutes();
            let seconds = now.getSeconds();
            
            day = day.toString().length < 2 ? `0${day}` : day;        
            month = month.toString().length < 2 ? `0${month}` : month;            
            hour = hour.toString().length < 2 ? `0${hour}` : hour;
            min = min.toString().length < 2 ? `0${min}` : min;            
            seconds = seconds.toString().length < 2 ? `0${seconds}` : seconds;

            this.info_date = '';
            this.info_date = `${now.getFullYear().toString().substring(2,4)}.${month}.${day} ${hour}:${min}:${seconds}`;
            
            this.timer = 2000 ;

            this.first = document.getElementById('first');
            this.second = document.getElementById('second');
            this.third = document.getElementById('third');
            this.move = 2;
            this.dataCnt = 1;
            this.listCnt = 1;      

            this.first.children[1].innerHTML = this.rollingData[0].address;
            this.first.children[2].innerHTML = this.rollingData[0].score;
            
            this.fnRolling();
        },
        fnRolling(){
            this.handle= setTimeout(() => {
                if(this.move == 2){
                    this.first.classList.remove('card_sliding')
                    this.first.classList.add('card_sliding_after')

                    this.second.classList.remove('card_sliding_after')
                    this.second.classList.add('card_sliding')

                    this.third.classList.remove('card_sliding_after')
                    this.third.classList.remove('card_sliding')

                    this.move = 0
                } 
                else if (this.move == 1){
                    this.first.classList.remove('card_sliding_after')
                    this.first.classList.add('card_sliding')

                    this.second.classList.remove('card_sliding_after')
                    this.second.classList.remove('card_sliding')

                    this.third.classList.remove('card_sliding')
                    this.third.classList.add('card_sliding_after')

                    this.move = 2
                } else if (this.move == 0) {
                    this.first.classList.remove('card_sliding_after')
                    this.first.classList.remove('card_sliding')

                    this.second.classList.remove('card_sliding')
                    this.second.classList.add('card_sliding_after')

                    this.third.classList.remove('card_sliding_after')
                    this.third.classList.add('card_sliding')

                    this.move = 1
                }
                
                if(this.dataCnt < (this.rollingData.length - 1)) {
                    document.getElementById('rolling_box').children[this.listCnt].children[1].innerHTML = this.rollingData[this.dataCnt].address;
                    document.getElementById('rolling_box').children[this.listCnt].children[2].innerHTML = this.rollingData[this.dataCnt].score;
                    this.dataCnt++
                } 
                else if(this.dataCnt == this.rollingData.length - 1) {
                    document.getElementById('rolling_box').children[this.listCnt].children[1].innerHTML = this.rollingData[this.dataCnt].address;
                    document.getElementById('rolling_box').children[this.listCnt].children[2].innerHTML = this.rollingData[this.dataCnt].score;
                    this.dataCnt = 0
                }

                if(this.listCnt < 2) {
                    this.listCnt++
                }
                else if (this.listCnt == 2) {
                    this.listCnt = 0
                }
                this.fnRolling();
            }, this.timer);
        },
        async fnMount(){
            await this.fnDelay(2000);   
            if(document.querySelector('.doughnut')){
                this.fnInit();
                this.fnMake(80, document.querySelector('.doughnut'), 'rgb(255, 90, 86)');   
            }         
        },
        async fnDelay(timeToDelay){
            return new Promise((resolve) => setTimeout(resolve, timeToDelay));
        },
        async fnMake(percent, classname, color){                 
            this.isok = true;        
            for(let i = 1; i <= percent; i++){
                document.querySelector('.doughnut').style.background = "conic-gradient(" + color + " 0% " + i + "%, rgba(173, 173, 173, 0.44) " + i + "% 100%)";
                await this.fnDelay(10);
            }
        },
        fnInit() {            
            this.isman = true;
                if (this.opts) {
                return
            }
            this.opts = this.slots.map( (data, i) => {
                const slot = this.$refs.slots[i];
                const choice = Math.floor( Math.random() * data.items.length )
                const opts = {
                    el: slot.querySelector('.slot__wrap'),
                    finalPos: choice * 180,
                    startOffset: 2000 + Math.random() * 500 + i * 500,
                    height: data.items.length * 180,
                    duration: 3000 + i * 700, 
                    isFinished: false,
                };
                return opts;
            });
            next( this.fnAnimate )
        },
        fnAnimate(timestamp) {
            if (this.startedAt == null) {
                this.startedAt = timestamp
            }

            const timeDiff = timestamp - this.startedAt;

            this.opts.forEach( opt => {
                if (opt.isFinished) {
                    return
                }

                const timeRemaining = Math.max(opt.duration - timeDiff, 0)
                const power = 3
                const offset = ( Math.pow(timeRemaining, power) / Math.pow(opt.duration, power) ) * opt.startOffset

                const pos = -1 * Math.floor((offset + opt.finalPos) % opt.height)
                if(opt.el){
                    opt.el.style.transform = "translateY(" + pos + "px)";
                }                

                if ( timeDiff > opt.duration ) {
                    console.log('finished', opt, pos, opt.finalPost)
                    opt.isFinished = true
                }
            });

            if (this.opts.every( o => o.isFinished )) {
                this.opts = null
                this.startedAt = null
                this.isman = false;
                this.fnMount();
            } 
            else {
                next( this.fnAnimate )
            }
        },                

        fnAnalyze(){
            window.VWO = window.VWO || [];
            VWO.event = VWO.event || function () {VWO.push(["event"].concat([].slice.call(arguments)))};
            VWO.event("start_survey");

            this.acPayStatus(false);
            this.$router.push('/analyze/type');            
        },
        fnCampaign(){            
            if(this.$route.query.utm_campaign){                
                if(this.$route.query.utm_campaign.substring(0, 14) === 'trial_facebook'){                    
                    const characters ='abcdefghijklmnopqrstuvwxyz1234567890';
                    let result = '';
                    const charactersLength = characters.length;
                    for (let i = 0; i < 30; i++) {
                        result += characters.charAt(Math.floor(Math.random() * charactersLength));
                    }                    
                    localStorage.zs_rtl_id = `${result}`;
                }
            }
        } 
    },
    mixins: [alertBase]  
}
</script>
<style>
.slot-machine{
    width: 100%;
    height: 100%;
}
.slot{
    width:100%;
    height: 100%;
}
.slot__window {
  width:100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
}
.slot__item {
    height: 150px;
    font-size:40px;
}
.circle_finish{
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 75px;
    margin: auto;
    display: block;    
}
.wrap {
  position: relative;
}
.container {
  display: flex;
  gap: 15px;
}
.chart{
    position: relative;
    border-radius: 50%;
    transition: 0.3s; 
    padding: 5%;
    margin: 30px auto;
    width: 194px;
    height: 194px;
}
span.center { 
    background: #fff;
    display: block; 
    position: absolute; 
    top: 50%;
    left: 50%;
    width: 140px;
    height: 140px;
    padding: 15px;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    transform: translate(-50%, -50%); 
    font-weight: 800;
    font-size: 25px;
    color: #FF5A56;
}
.content_middle_01{
    text-align: left;
    margin-top:45px;
}
.content_middle_01 img{
    vertical-align: middle;
    align-items: center;
}
.content_middle_01 span{
    vertical-align: middle;
    align-items: center;
    font-weight: 700;
    font-size: 17px;
    line-height: 35px;
    color: #3D3D3D;    
    margin-left: 7px;
}
.content_middle_02{
    text-align: left;
    margin-top:41px;
}
.content_middle_02 img{
    vertical-align: middle;
    align-items: center;
}
.content_middle_02 span{
    vertical-align: middle;
    align-items: center;
    font-weight: 700;
    font-size: 17px;
    line-height: 35px;
    color: #3D3D3D;    
    margin-left: 7px;
}
.content_middle_02_detail01{
    background: #FFFFFF;
    margin-top:14px;
    position: relative;
    cursor: pointer;

    border: 2px solid #B9CFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;    
}
.content_middle_02_detail02{
    border-bottom: 1px solid #B9CFFF;
    box-sizing: border-box;
    padding:12px 14px 11px 16px;
    height: 43px;
}
.content_middle_02_detail02 img{
    height: 19px;
    width: 18px;
    position: absolute;
    left: 16px;
    cursor: pointer;
}
.content_middle_02_detail02 span{
    font-weight: 900;
    font-size: 14px;
    line-height: 22px;
    float: right;  
    margin-right: 12px;
    color: #5087FF;
}
.content_middle_02_detail03{
    padding:14px 15px 0px 15px;
}
.content_middle_02_detail03 p{
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    color: #6C6C6C;
}
.content_middle_02_detail01_add{
    background: #FFFFFF;
    border: 2px solid #B9CFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-top: 22px;
    text-align: center;
    height: 146px;
    cursor: pointer;
    position: relative;
}
.content_middle_02_detail01_add.circle{
    width: 56.69px;
    height: 56.69px;
    border-radius: 50%;
    background: #E3ECFF;
    position: relative;
    border:none;
    box-shadow:unset;
    position: absolute;
    left: 50%;
    top: 40%;
    margin-left: -27px;
    margin-top: -27px;    
}
.content_middle_02_detail01_add p{
    font-size: 34px;
    font-weight: 900;
    color: #5087FF;    
    line-height: unset;
    margin:unset;
}
.content_middle_02_detail01_add_txt p{
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    padding-top:90px;
    color: #6C6C6C;
}
.content_top03{
    margin-top:16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;    
}
.content_top03_detail02 h2{
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    color: #3D3D3D;    
}
.tempman{
    background: #ffffff;
    width: 194px;
    height: 194px;
    border: 27px solid rgba(173, 173, 173, 0.44);
    position: relative;
    border-radius: 50%;
    transition: 0.3s;
    padding: 5%;
    margin: 30px auto;
    width: 194px;
    height: 194px;
    text-align: center;
    font-weight: 800;
    font-size: 50px;
    color: #FF5A56;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.main_landing_btn{
    text-align: center;
    /* margin-bottom: 90px; */
    margin-bottom: var(--ctaHeight);
    display: inline-flex;
    justify-content: center;
    align-content: center;
    width:80%;
    position: relative;
    height: auto;
}
.main_landing_btn_speechbubble{
    filter:drop-shadow(0px 0px 4.53px rgba(0, 0, 0, 0.15));
    position: absolute;
    top: -60%;  
}
.main_landing_btn .main_landing_next{
    box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 10%);
    border-radius: 20px;
    font-weight: 700;
    font-size: 15px;
    line-height: 55px;
    color: #2B2B2B;
    width: 100%;
    height: 55px;
    /* margin-bottom: 5vh; */
    position: relative;    
    background:#0044DA;
    color:#FFFFFF;
}
.main_landing_btn .main_landing_next a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
}
.main_landing_btn .main_landing_next::after {
    content: "";
    background: url('../../assets/images/home/arraw_icon.svg')no-repeat;
    width: 12px;
    height: 15px;
    right: 15px;
    top: 19px;
    position: absolute;
}
.main_landing_report_info{
    background: #F8F8F8;
    border-radius: 20px;    
    padding:30px;
}
.main_landing_report_title{
    display: inline-block;
    width:100%;
}
.main_landing_report_title span{
    font-weight: 700;
    font-size: 14px;
    color: #444444;    
    float: left;
}
.main_landing_report_title > .right_date_txt{
    font-weight: 500;
    font-size: 12px;
    color: #9C9C9C;
    float: right;
}
.main_landing_report_txt{
    background: #FFFFFF;
    border: 1px solid #CFDEFE;
    border-radius: 13px;    
    height: 40px;
    /* display: flex;
    justify-content: space-between; */
    width:100%;
    padding:5px 18px 5px 18px;
    margin-top:10px;
}
.main_landing_report_txt span{
    font-weight: 700;
    font-size: 14px;
    color: #444444;    
    line-height: 27px;
}
.main_landing_report_txt span:nth-child(1){
    float: left;
}
.main_landing_report_txt .right_red_txt{
    font-weight: 900;
    font-size: 14px;    
    color: #FF5A56;
    float: right;
}
.main_landing_complete_flex{
    display: flex;
    justify-content: center;
    justify-content: space-between;
    padding: 24px 43px 24px 43px;
    gap: 20px;
    width:auto;
    height: auto;
}
.main_landing_report_txt li.current{
    top: 0;
    transition: top 0.5s ease;
}
.main_landing_report_txt li.next{
    top: -36px;
}

/* .rolling_box{
    width: 300px;
    height: 40px;
    text-align: center;
    border: 1px solid #848484;
} */
.main_landing_report_txt ul {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.main_landing_report_txt ul li {
    width: 100%;
    height: 100%;
    transition: .5s;
    position:absolute;
    transition: top .75s;
    top: 100%;
    z-index: 1;
    background-color: #ffffff;
}
.card_sliding{
    top: 0 !important;
    z-index: 100 !important;
} 
.card_sliding_after{
    top: -100% !important;
    z-index: 10 !important;
}
.rolling_box ul li p {
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
}
.before_slide {
    transform: translateY(100%);
}
.after_slide {
    transform: translateY(0);
}
.mode-fade-enter-active, .mode-fade-leave-active {
  transition: opacity .8s ease
}
.mode-fade-enter-from, .mode-fade-leave-to {
  opacity: 0
}
.btn_blue_scan {
    margin-top: 4px;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 68, 218);
    cursor: pointer;
    border-radius: 20px;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
}
.pricing_slide_wrapper {
  background:#FFF;
  padding:40px 0 40px 25px;
}
.pricing_slide_title p{
  text-align: left;
  color: #1F1F1F;
  font-size: 20.387px;
  font-weight: 700;
  line-height: 27.183px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.pricing_slide_title span {
  color: #FF5A56;
}
.swiper-slide {
    width: 100px;
}
.landing_slide_wrapper{
    margin-top:30px ;
    border-radius: 12px;
    border: 2px solid #E3ECFF;
    background: #FFF;
    padding: 22px 11px 22px 14px;
}
.landing_slide_wrapper >p {
    color: #3D3D3D;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px !important;
    margin-bottom: 0;
    margin-top: 14px;
    text-align: left;
}
hr.slide_hr{
    border: 0; 
    height: 2px; 
    background-color: #E3ECFF; 
}
.double_quotation{
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 3;
}
.slide_title{
    display: flex;
    justify-content: flex-start;
    gap:10px;
}
.slide_title p{
    color: #5087FF;
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 0px;
    text-align: left;
}
.slide_owner_info{
    display: flex;
    gap:20px;
    color: #777;
    font-family: Noto Sans KR;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 50.867px; 
}
.content_main{
    padding:35px 20px 100px 20px;
    text-align: center;
}
.main_home{
    margin-top:20px;
}
.main_landing_title02{
    width: auto; 
    height: auto;
}
.main_home .main_realtor{
    font-weight:900;
    font-size:14px;
    color:#313131;
    margin-top:23px;
}
.main_landing_kimscan{
    height:410px;
}
.main_landing_kimscan{
    margin-top:31px; 
    width: auto; 
    height: auto;
}

@media screen and (max-width: 1080px){
    .main_landing_btn{
        margin-bottom: calc(var(--ctaHeight) + 35px);
    }
}
</style>
