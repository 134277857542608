import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import VueSkeletonLoader from 'skeleton-loader-vue';
import VueWaypoint from 'vue-waypoint';
import VueClipboard from 'vue-clipboard2'
import mixpanel from 'mixpanel-browser';
import TextareaAutosize from 'vue-textarea-autosize'
import bridgeLoginApp from './plugins/bridgeApp';
import infiniteScroll from 'vue-infinite-scroll'
import Meta from 'vue-meta'

Vue.config.productionTip = false
Vue.component('vue-skeleton-loader', VueSkeletonLoader);
Vue.use(VueWaypoint);
Vue.use(bridgeLoginApp);
Vue.use(VueClipboard);
Vue.use(TextareaAutosize);
Vue.use(infiniteScroll);
Vue.use(Meta, {
  attribute: 'data-vue-meta'
});

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {debug: true, track_pageview: true})
Vue.prototype.$mixpanel = mixpanel;

var vm = new Vue({
  router,
  store,
  axios,   
  bridgeLoginApp,
  render: h => h(App)
});

window.app = vm.$mount('#app');


